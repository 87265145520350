<template>
  <div>
    <b-col>
      <div class="box-total">
        <div class="total-vendas info"></div>
        <div>
          <p class="total-vendas">
            Total de leads:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>
      <div class="header">
        <b-dropdown id="filterLeads" left style="margin-top: 12px">
          <template id="button-filter" #button-content>
            <svg
              v-if="itemSelected == 'circle'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--bluee)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
            </svg>
            <svg
              v-else-if="itemSelected == 'poligono'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--bluee)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-octagon"
            >
              <polygon
                points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"
              ></polygon>
            </svg>
            <svg
              v-else-if="itemSelected == 'square'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--bluee)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-square"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            </svg>
            <svg
              v-else-if="itemSelected == 'lead-check'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--bluee)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-square"
            >
              <polyline points="9 11 12 14 22 4"></polyline>
              <path
                d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
              ></path>
            </svg>
            <svg
              v-else-if="itemSelected == 'lead-update'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--bluee)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minus-square"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
            <svg
              v-else-if="itemSelected == 'lead-error'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--bluee)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-square"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="9" y1="9" x2="15" y2="15"></line>
              <line x1="15" y1="9" x2="9" y2="15"></line>
            </svg>
            <span class="filtro">{{ filterStatus }}</span>
          </template>
          <b-dropdown-item
            id="filter-item"
            @click="fetchLeads('', 'a'), (itemSelected = 'circle')"
          >
            <img src="@/assets/img/icons/circle.svg" alt="dots" />
            Todos os status
          </b-dropdown-item>
          <b-dropdown-item
            id="filter-item"
            @click="fetchLeads('', 'waiting'), (itemSelected = 'poligono')"
          >
            <img src="@/assets/img/icons/poligono.svg" alt="dots" />
            Em espera
          </b-dropdown-item>
          <b-dropdown-item
            id="filter-item"
            @click="fetchLeads('', 'processing'), (itemSelected = 'square')"
          >
            <img src="@/assets/img/icons/square.svg" alt="dots" />
            Processando
          </b-dropdown-item>
          <b-dropdown-item
            id="filter-item"
            @click="fetchLeads('', 'created'), (itemSelected = 'lead-check')"
          >
            <img src="@/assets/img/icons/lead-check.svg" alt="dots" />
            Lead criado
          </b-dropdown-item>
          <b-dropdown-item
            id="filter-item"
            @click="fetchLeads('', 'updated'), (itemSelected = 'lead-update')"
          >
            <img src="@/assets/img/icons/lead-update.svg" alt="dots" />
            Lead atualizado
          </b-dropdown-item>
          <b-dropdown-item
            id="filter-item"
            @click="fetchLeads('', 'errored'), (itemSelected = 'lead-error')"
          >
            <img src="@/assets/img/icons/lead-error.svg" alt="dots" />
            Algo deu errado
          </b-dropdown-item>
        </b-dropdown>
  
        <BaseButton @click="$emit('reset')" variant="black">
          Importar dados
        </BaseButton>
      </div>
      <b-row class="Table-header">
        <b-col cols="1" class="d-none gap-label d-md-block">
          # id Lead
        </b-col>
  
        <b-col cols="2" class="d-none gap-label d-md-block">
          Nome Lead
        </b-col>
  
        <b-col cols="3" class="d-none gap-label d-md-block">
          Endereço de e-mail
        </b-col>
        <b-col cols="2" class="d-none gap-label d-md-block"> Telefone </b-col>
        <b-col cols="2" class="d-none gap-label d-md-block"> Status </b-col>
        <b-col cols="2" class="d-none gap-label d-md-block"> Mensagem </b-col>
      </b-row>
  
      <b-row v-for="(item, index) in leads" :key="index" class="Table-body">
        <b-col cols="1" class="d-none gap-label d-md-block">
          {{ item.id }}
        </b-col>
  
        <b-col cols="2" class="d-none gap-label d-md-block">
          {{ item.parsed_lead.name ? item.parsed_lead.name : "Sem nome" }}
        </b-col>
  
        <b-col cols="3" class="d-none gap-label d-md-block">
          <span v-if="item.parsed_lead && item.parsed_lead.email">
            {{ item.parsed_lead.email }}
          </span>
          <span
            v-else-if="
              item.raw_response &&
              item.raw_response.obj_lead &&
              item.raw_response.obj_lead.email
            "
          >
            {{ item.raw_response.obj_lead.email }}
          </span>
          <span v-else> Sem e-mail </span>
        </b-col>
        <b-col cols="2" class="d-none gap-label d-md-block">
          {{ item.parsed_lead.telefone ? item.parsed_lead.telefone : item.parsed_lead.phone }}
        </b-col>
        <b-col cols="2" class="d-none gap-label d-md-block">
          <span class="criado" v-if="item.status == 'created'"
            >LEAD CRIADO</span
          >
          <span class="atualizado" v-else-if="item.status == 'updated'"
            >LEAD ATUALIZADO</span
          >
          <span class="error" v-else-if="item.status == 'errored'"
            >ALGO DEU ERRADO</span
          >
        </b-col>
        <b-col cols="2" class="d-none gap-label d-md-block">
          <span
            v-if="
              item && item.raw_response && item.raw_response.msg == 'sucesso'
            "
            >{{ item.raw_response.sucesso }}</span
          >
          <span
            v-if="
              item && item.raw_response && item.raw_response.msg == 'erro'
            "
            >{{ item.raw_response.erro }}</span
          >
        </b-col>
      </b-row>
    </b-col>
    <b-row>
      <b-col>
        <Paginate
          v-if="leads && leads.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          :disabled="true"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Paginate from "@/components/Paginate";
import BaseButton from "@/components/BaseButton";
import ImportService from "@/services/resources/ImportService";
const serviceImport = ImportService.build();
export default{
  props: ['importacao'],
  components: {
    Paginate,
    BaseButton
  },
  data(){
    return{
      total: 0,
      leads: null,
      itemSelected: "circle",
      filterStatus: "Todos os status",
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    }
  },
  methods: {
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchLeads(page);
      this.itemSelected = "circle";
      this.filterStatus = "Todos os status";
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchLeads(qtd);
    },
    fetchLeads(page, status) {
      let id = this.importacao.id;

      if (status) {
        this.status = status == "a" ? "" : status;
        this.pagination.currentPage = 1;
      }

      serviceImport
        .read(
          `/${id}?page=${
            this.pagination.currentPage ? this.pagination.currentPage : 1
          }&status=${this.status ? this.status : ""}`
        )
        .then((resp) => {
          this.leads = resp.data;
          this.pagination.totalPages = resp.last_page;
          this.pagination.currentPage = resp.current_page;
          this.pagination.perPage = resp.per_page;
          this.total = resp.total;
        });
    },
  },
  mounted(){
    this.fetchLeads("", "a");
    this.itemSelected = "circle";
    this.filterStatus = "Todos os status";
  },
  watch: {
    itemSelected() {
      if (this.itemSelected == "circle") {
        this.filterStatus = "Todos os status";
      } else if (this.itemSelected == "poligono") {
        this.filterStatus = "Em espera";
      } else if (this.itemSelected == "square") {
        this.filterStatus = "Processando";
      } else if (this.itemSelected == "lead-check") {
        this.filterStatus = "Lead criado";
      } else if (this.itemSelected == "lead-update") {
        this.filterStatus = "Lead atualizado";
      } else {
        this.filterStatus = "Algo deu errado";
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.atualizado {
  color: var(--bluee) !important;
}

.criado {
  color: var(--greenn) !important;
}

.error {
  color: #ff0846 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.filtro {
  margin: 0 !important;
  color: var(--bluee) !important;
  align-items: center;
  transition: 0.3s ease;
  font-weight: bold;
  font-size: 14px;
}

#button-filter svg {
  color: var(--bluee) !important;
}

.Table-body {
  min-height: 0 !important;
}
</style>